import React, { useEffect } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";

type ShiftsProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const Shifts: React.FC<ShiftsProps> = ({ setBreadcrumb, title }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const shifts = [
    {
      shift: `${t("shift1")}`,
      start: "08:00",
      end: "13:59",
    },
    {
      shift: `${t("shift2")}`,
      start: "14:00",
      end: "19:59",
    },
    {
      shift: `${t("shift3")}`,
      start: "20:00",
      end: `01:59 ${t("nextDay")}`,
    },
    {
      shift: `${t("shift4")}`,
      start: "02:00",
      end: `07:59 ${t("nextDay")}`,
    },
  ];

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("shift")}`,
              field: "shift",
              flex: 1,
              renderCell: (params: GridRenderCellParams) =>
                `${params.row.shift}`,
            },
            {
              headerName: `${t("startHour")}`,
              field: "startHour",
              flex: 1,
              renderCell: (params: GridRenderCellParams) =>
                `${params.row.start}`,
            },
            {
              headerName: `${t("endHour")}`,
              field: "endHour",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => `${params.row.end}`,
            },
          ]}
          rows={shifts}
        />
      </Grid>
    </Grid>
  );
};

export default Shifts;
