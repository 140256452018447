import { logout } from "../../api/services/loginService";
import { useNavigate } from "react-router-dom";
import React, { MouseEvent, useEffect, useState } from "react";
import { roles, routes } from "../pages/costants";
import { AppBar, Breadcrumbs, Grid, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../images/logo_orizzontale.svg";
import { useTranslation } from "react-i18next";
import {
  AccountCircleOutlined,
  PowerSettingsNewOutlined,
  PersonOutlineOutlined,
  NavigateNext,
} from "@mui/icons-material";
import { darkColor } from "../custom/utils";
import CustomIconButton from "../custom/CustomIconButton";
import Divider from "@mui/material/Divider";
import CustomText from "../custom/CustomText";
import { getProfile } from "../../api/services/userService";
import CustomTag from "../custom/CustomTag";

type HeaderProps = {
  SetNavbarIsCollapsed: (collapsed: boolean) => void;
  navbar_collapsed: boolean;
  isMobile: boolean;
  breadcrumbs: any[];
  visibility: "visible" | "hidden";
  role: string;
};

const Header: React.FC<HeaderProps> = ({
  SetNavbarIsCollapsed,
  navbar_collapsed,
  isMobile,
  breadcrumbs,
  visibility,
  role,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const logOut = () => {
    logout().then((res) => {
      if (res && res.status && res.status === "success") {
        navigate(res.location);
      }
    });
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [profile, setProfile] = useState<any>({});
  useEffect(() => {
    getProfile().then((res: any) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        setProfile({
          ...res.user,
        });
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return null;
  }

  return (
    <AppBar
      variant="outlined"
      position="fixed"
      style={{ backgroundColor: "white", zIndex: 1387, visibility: visibility }}
    >
      <Grid container alignItems="center">
        {role !== roles.manager && (
          <Grid item style={{ marginRight: "8px" }}>
            <CustomIconButton
              icon={<MenuIcon htmlColor="#ffffff" />}
              onClick={() => SetNavbarIsCollapsed(!navbar_collapsed)}
            />
          </Grid>
        )}
        {!isMobile && (
          <Grid item>
            <img
              style={{ cursor: "pointer", height: "60px" }}
              alt="logo"
              src={logo}
              onClick={() => navigate(routes.mainPage)}
            />
          </Grid>
        )}
        {!isMobile && (
          <Grid item>
            <Breadcrumbs
              separator={<NavigateNext htmlColor="#ffffff" fontSize="small" />}
              style={{ paddingLeft: "32px", textTransform: "uppercase" }}
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
        )}
        <Grid item xs />
        <Grid item style={{ textAlign: "right" }}>
          <CustomTag
            label={
              profile.roles && profile.roles.length > 0
                ? profile.roles[0]
                : "---"
            }
            type="outlined"
          />
          <CustomText
            color={darkColor}
            label={profile?.email?.split("@")[0]}
            type="body1"
          />
        </Grid>
        <Grid item>
          <CustomIconButton
            icon={<AccountCircleOutlined htmlColor="#ffffff" />}
            onClick={(event: MouseEvent<HTMLDivElement>) =>
              setAnchorEl(event.currentTarget)
            }
          />
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            i18n.language === "en"
              ? i18n.changeLanguage("it")
              : i18n.changeLanguage("en");
          }}
        >
          {i18n.language === "en" ? "🇮🇹 Italiano" : "🇬🇧 English"}
        </MenuItem>
        <Divider
          style={{ borderBottomWidth: "10px", borderColor: darkColor }}
        />
        <MenuItem
          onClick={() => {
            navigate(routes.profile);
          }}
        >
          <PersonOutlineOutlined />
          {t("profile")}
        </MenuItem>
        <MenuItem onClick={logOut}>
          <PowerSettingsNewOutlined />
          {t("logout")}
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Header;
