import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { darkColor } from "../../custom/utils";
import CustomText from "../../custom/CustomText";
import CustomList from "../../custom/CustomList";
import { getDashboardController } from "../../../api/services/dashboardService";
import CustomLoading from "../../custom/CustomLoading";
import CustomCard from "../../custom/CustomCard";

type DashboardManagerMobileGearDetailsProps = {
  setNavbarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const DashboardManagerMobileGearDetails: React.FC<
  DashboardManagerMobileGearDetailsProps
> = ({ setNavbarCollapsed, setHeaderCollapsed }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const batch_id = searchParams.get("batch_id");
  const shift_id = searchParams.get("shift_id");
  const crane_id = searchParams.get("crane_id");
  //const crane_driver = searchParams.get("crane_driver");
  const date = searchParams.get("date");
  const controller = searchParams.get("controller");

  useEffect(() => {
    setNavbarCollapsed(true);
    setHeaderCollapsed(true);
    return () => {
      setHeaderCollapsed(false);
    };
  }, []);

  const [dashboard, setDashboard] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (batch_id && shift_id && crane_id && date) {
      getDashboardController(batch_id, shift_id, crane_id, date).then((res) => {
        if (res && res.dashboard) {
          setDashboard({ ...res.dashboard });
        }
        setLoading(false);
      });
    }
  }, [batch_id, shift_id, crane_id, date]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "10px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={`${t("gangDetails")}`}
          goBack={() => navigate(-1)}
        />
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          backgroundColor: darkColor,
          borderRadius: "10px",
          color: "white",
          padding: "1px",
          marginLeft: "16px",
          marginTop: "10px"
        }}
        textAlign="center"
      >
        <CustomList
          dense
          listElement={[
            {
              primaryText: `${t("order")}`,
              action: dashboard?.voyage || "N.A.",
            },
            {
              primaryText: `${t("description")}`,
              action: dashboard?.description || "N.A.",
            },
            {
              primaryText: `${t("shifts")}`,
              action: dashboard?.crane_moves_hours?.date_shift || "N.A.",
            },
            {
              primaryText: `${t("crane")}`,
              action: dashboard?.crane_id || "N.A.",
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomCard
          content={
            <Grid container textAlign="center">
              <Grid item xs={12}>
                <CustomText label={`${t("gmph")}`} type="h3" />
              </Grid>
              <Grid item xs={12}>
                <CustomText
                  label={dashboard?.crane_moves_hours.value || "N.A."}
                  type="h2"
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>

      <Grid item xs={12}>
        <CustomList
          dense
          listElement={[
            {
              primaryText: `${t("totalMoves")}`,
              secondaryText: String(
                dashboard?.vessel_projections?.total_moves || "N.A."
              ),
            },
            {
              primaryText: `${t("toDo")}`,
              secondaryText: String(
                dashboard?.vessel_projections?.moves_to_do || "N.A."
              ),
            },
            {
              primaryText: `${t("moves")}`,
              secondaryText: String(
                dashboard?.crane_projections?.mvs || "N.A."
              ),
            },
            {
              primaryText: `${t("firstLift")}`,
              secondaryText:
                dashboard?.crane_projections?.fl === "0001-01-01T00:00:00Z"
                  ? "N.A."
                  : new Date(dashboard?.crane_projections?.fl)
                    .toISOString()
                    .replace("T", " ")
                    .split(".")[0],
            },
            {
              primaryText: `${t("lastLift")}`,
              secondaryText:
                dashboard?.crane_projections?.ll === "0001-01-01T00:00:00Z"
                  ? "N.A."
                  : new Date(dashboard?.crane_projections?.ll)
                    .toISOString()
                    .replace("T", " ")
                    .split(".")[0],
            },
            {
              primaryText: `${t("workingTime")}`,
              secondaryText: dashboard?.crane_projections?.work || "N.A.",
            },
            {
              primaryText: `${t("craneDriver")}`,
              secondaryText: dashboard?.crane_driver || "N.A.",
            },
            {
              primaryText: `${t("foreman")}`,
              secondaryText: dashboard?.foreman || "N.A.",
            },
            {
              primaryText: `${t("controller")}`,
              secondaryText: controller || "N.A.",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardManagerMobileGearDetails;
