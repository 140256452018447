import React, { useEffect, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { getDashboardControllerUrl, mainUrl } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomSelect from "../../custom/CustomSelect";
import { useSnackbar } from "notistack";
import { getDashboardController } from "../../../api/services/dashboardService";
import { getCranes } from "../../../api/services/craneService";
import { getOrders } from "../../../api/services/orderService";
import CustomLoading from "../../custom/CustomLoading";
import Autocomplete from "@mui/material/Autocomplete";

type DashboardProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const DashboardOverview: React.FC<DashboardProps> = ({
  setBreadcrumb,
  title,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<any[]>([]);

  const [dateShift, setDateShift] = useState({
    date_shift: "",
    shift: "",
  });

  // Function to get the current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  // Function to get the current date minus one day in YYYY-MM-DD format
  const getCurrentDateMinusOneDay = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split("T")[0];
  };

  // useEffect to update the date_shift when the shift changes
  useEffect(() => {
    let newDateShift = "";
    if (
      dateShift.shift === "1" ||
      dateShift.shift === "2" ||
      dateShift.shift === "3"
    ) {
      newDateShift = getCurrentDate();
    } else if (dateShift.shift === "4") {
      newDateShift = getCurrentDateMinusOneDay();
    }

    setDateShift((prevState) => ({
      ...prevState,
      date_shift: newDateShift,
    }));
  }, [dateShift.shift]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [values, setValues] = useState({
    batch_id: "",
    shift_id: "",
    crane_id: "",
    date_shift: "",
  });
  const [cranes, setCranes] = useState<any[]>([]);

  useEffect(() => {
    getCranes().then((res: any) => {
      if (res && res.cranes) {
        setCranes([...res.cranes]);
      } else {
        setCranes([]);
      }
    });

    getOrders().then((res: any) => {
      if (res && res.orders) {
        setOrders([...res.orders]);
      } else {
        setOrders([]);
      }
    });

    setLoading(false);
  }, []);

  if (loading) {
    return <CustomLoading />;
  }

  const handleSubmit = () => {
    //check if all fields are filled
    if (
      !values.batch_id ||
      !dateShift.shift ||
      !values.crane_id ||
      !dateShift.date_shift
    ) {
      enqueueSnackbar(t("fillAllFields"), { variant: "error" });
      return;
    }
    let tmpCrane = cranes?.filter(
      (crane: any) => crane.description === values.crane_id
    )[0];
    getDashboardController(
      values.batch_id!.split(" - ")[0],
      dateShift.shift!,
      tmpCrane.code,
      dateShift.date_shift!
    ).then((res) => {
      console.log("res", res);
      if (res && res.dashboard) {
        navigate(
          getDashboardControllerUrl(
            values.batch_id!.split(" - ")[0],
            dateShift.shift,
            tmpCrane.code,
            dateShift.date_shift
          )
        );
      } else {
        enqueueSnackbar(t("noResources"), { variant: "error" });
      }
      setLoading(false);
    });
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  value={
                    values.batch_id
                      ? orders
                          ?.map(
                            (order: any) =>
                              order.order_id + " - " + order.description
                          )
                          .filter((v) => v.includes(values.batch_id))[0]
                      : ""
                  }
                  disablePortal
                  id="combo-box-demo"
                  options={orders?.map(
                    (order: any) => order.order_id + " - " + order.description
                  )}
                  onChange={(e, value) => {
                    console.log("value", value);
                    if (value) {
                      setValues({ ...values, batch_id: value });
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label={`${t("batchId")}`} />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("shiftId")}`}
                  options={[
                    { label: t("shift1"), value: "1" },
                    { label: t("shift2"), value: "2" },
                    { label: t("shift3"), value: "3" },
                    { label: t("shift4"), value: "4" },
                  ]}
                  value={dateShift.shift}
                  handleChange={(e) =>
                    setDateShift({ ...dateShift, shift: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  value={
                    values.crane_id
                      ? cranes
                          ?.map((crane: any) => crane.description)
                          .filter((c) => c.includes(values.crane_id))[0]
                      : ""
                  }
                  disablePortal
                  id="combo-box-demo"
                  options={cranes?.map((crane: any) => crane.description)}
                  onChange={(e, value) => {
                    if (value) {
                      setValues({ ...values, crane_id: value });
                    } else {
                      setValues({ ...values, crane_id: "" });
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label={`${t("craneId")}`} />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("dateShift")}
                  type="date"
                  value={dateShift.date_shift}
                  handleChange={(e) =>
                    setDateShift({ ...dateShift, date_shift: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() => {
                setValues({
                  batch_id: "",
                  shift_id: "",
                  crane_id: "",
                  date_shift: "",
                });
                setDateShift({
                  date_shift: "",
                  shift: "",
                });
              }}
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardOverview;
