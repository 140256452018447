import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl, routes, updateUserUrl, resetPasswordUrl } from "../costants";
import { deleteUser, getAllUsers } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import CustomTag from "../../custom/CustomTag";
import CustomIconButton from "../../custom/CustomIconButton";
import { greyColor } from "../../custom/utils";
import {
  DeleteForeverOutlined,
  ModeEdit,
  LockReset,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { DeleteUserRequest } from "../../../api/requests/userService";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CustomText from "../../custom/CustomText";
import Tooltip from "@mui/material/Tooltip";

type UsersListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const UsersList: React.FC<UsersListProps> = ({
  setBreadcrumb,
  title,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  const [users, setUsers] = useState<any[]>([]);
  useEffect(() => {
    getAllUsers().then((res) => {
      if (res && res.users) {
        setUsers(
          [...res.users].filter((user: any) => !user.roles.includes("admin"))
        );
      }
      setLoading(false);
    });
  }, [reload]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("username")}`,
              field: "email",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>{params.row.email.split("@")[0]}</>
              ),
            },
            { headerName: `${t("name")}`, field: "name", flex: 1 },
            {
              headerName: `${t("role")}`,
              field: "roles",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.roles && params.row.roles.length > 0 ? (
                    <CustomTag
                      label={params.row.roles[0]}
                      type="filled"
                      color={greyColor}
                    />
                  ) : (
                    "---"
                  )}
                </>
              ),
            },
            {
              headerName: `${t("onlyAddress")}`,
              field: "user_info.address",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {params.row.user_info.address}, {params.row.user_info.city}{" "}
                  {params.row.user_info.province}
                </>
              ),
            },
            {
              headerName: `${t("zip")}`,
              field: "user_info.zip_code",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <> {params.row.user_info.zip_code}</>
              ),
            },
            {
              headerName: `${t("phone")}`,
              field: "user_info.phone",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <> {params.row.user_info.phone}</>
              ),
            },
            {
              headerName: "",
              field: "",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  <CustomIconButton
                    type="outlined"
                    icon={
                      <Tooltip title={t("edit")}>
                        <ModeEdit />
                      </Tooltip>
                    }
                    onClick={() => navigate(updateUserUrl(params.row.uid))}
                  />
                  <CustomIconButton
                    type="outlined"
                    icon={
                      <Tooltip title={t("resetPassword")}>
                        <LockReset />
                      </Tooltip>
                    }
                    onClick={() => navigate(resetPasswordUrl(params.row.uid))}
                  />
                  <CustomIconButton
                    type="outlined"
                    confirm
                    icon={
                      <Tooltip title={t("delete")}>
                        <DeleteForeverOutlined />
                      </Tooltip>
                    }
                    onClick={() =>
                      deleteUser(params.row.email, {
                        user_id: params.row.email.toString(),
                      } as DeleteUserRequest).then((res: any) => {
                        if (res && !res.error) {
                          enqueueSnackbar(t("userDeletedSuccess"), {
                            variant: "success",
                          });
                          setReload(!reload);
                        } else {
                          enqueueSnackbar(
                            `${t("userDeletedError")} ${res?.err?.message}`,
                            {
                              variant: "error",
                            }
                          );
                        }
                      })
                    }
                  />
                </>
              ),
            },
          ]}
          rows={users}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <CustomIconButton
            type="outlined"
            icon={<AddCircleOutlinedIcon fontSize="large" />}
            onClick={() => navigate(routes.createUsers)}
          />
          <CustomText type="h6" label={t("createUser")} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UsersList;
