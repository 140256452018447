import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl, updateOrderUrl } from "../costants";
import { deleteOrder, getAllOrders } from "../../../api/services/orderService";
import CustomLoading from "../../custom/CustomLoading";
import CustomIconButton from "../../custom/CustomIconButton";
import { DeleteForeverOutlined, ModeEdit } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { DeleteOrderRequest } from "../../../api/requests/orderService";
import Tooltip from "@mui/material/Tooltip";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomInput from "../../custom/CustomInput";

type OrdersListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string | ((id: string) => string);
};

const OrdersList: React.FC<OrdersListProps> = ({
  setBreadcrumb,
  title,
  link,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  const [orders, setOrders] = useState<any[]>([]);
  const [inputValueWork, setInputValueWork] = useState<string>(
    localStorage.getItem("SCTOrderIdWork") || ""
  );
  const [inputValueAll, setInputValueAll] = useState<string>(
    localStorage.getItem("SCTOrderIdAll") || ""
  );
  const [newOrders, setNewOrders] = useState<any[]>([]);
  const [newOrdersAll, setNewOrdersAll] = useState<any[]>([]);

  useEffect(() => {
    getAllOrders().then((res: any) => {
      console.log(res.orders);
      if (res && res.orders) {
        setOrders([...res.orders]);
        if (inputValueWork) {
          let newOrders = res.orders.filter((order: any) =>
            String(order.order_id)
              .toLocaleLowerCase()
              .includes(String(inputValueWork).toLocaleLowerCase())
          );
          setNewOrders(newOrders);
        } else {
          setNewOrders([...res.orders]);
        }
        if (inputValueAll) {
          let newOrders = res.orders.filter((order: any) =>
            String(order.order_id)
              .toLocaleLowerCase()
              .includes(String(inputValueAll).toLocaleLowerCase())
          );
          setNewOrdersAll(newOrders);
        } else {
          setNewOrdersAll([...res.orders]);
        }
      } else {
        setOrders([]);
        setNewOrders([]);
        setNewOrdersAll([]);
      }
      setLoading(false);
    });
  }, [reload]);

  //Method to get orders based on input in custom input field
  const getNewOrders = (order_id: string) => {
    setLoading(true);
    let newOrders = orders.filter((order: any) =>
      String(order.order_id)
        .toLocaleLowerCase()
        .includes(String(order_id).toLocaleLowerCase())
    );
    setNewOrders(newOrders);
    setLoading(false);
  };

  //Method to get orders based on input in custom input field
  const getNewOrdersAll = (order_id: string) => {
    setLoading(true);
    let newOrders = orders.filter((order: any) =>
      String(order.order_id)
        .toLocaleLowerCase()
        .includes(String(order_id).toLocaleLowerCase())
    );
    setNewOrdersAll(newOrders);
    setLoading(false);
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={`${t("toWork")}`} />
            <Tab label={`${t("all")}`} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <div role="tabpanel" hidden={tabValue !== 0}>
            <Grid item container xs={12} spacing={2}>
              {/* <Grid item xs /> */}
              <Grid item xs={4}>
                <CustomInput
                  label={t("orderId")}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setInputValueWork(e.target.value);
                    localStorage.setItem("SCTOrderIdWork", e.target.value);
                    getNewOrders(e.target.value);
                  }}
                  value={inputValueWork}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTable
                  columns={[
                    {
                      headerName: `${t("orderId")}`,
                      field: "orderId",
                      description: `${t("orderId")}`,
                      flex: 1,
                      filterable: false,
                      renderCell: (params: GridRenderCellParams) => (
                        <Link
                          style={{ color: "black" }}
                          to={
                            typeof link === "string"
                              ? link
                              : link(params.row.order_id)
                          }
                        >
                          {params.row.order_id}
                        </Link>
                      ),
                    },
                    {
                      headerName: `${t("description")}`,
                      field: "description",
                      description: `${t("description")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.description}</>
                      ),
                    },
                    {
                      headerName: `${t("numDocks")}`,
                      field: "dock_num",
                      description: `${t("numDocks")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.dock_number}</>
                      ),
                    },
                    {
                      headerName: `${t("dock")}`,
                      field: "dock",
                      description: `${t("dock")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.dock}</>
                      ),
                    },
                    {
                      headerName: `${t("status")}`,
                      field: "status",
                      description: `${t("status")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.status}</>
                      ),
                    },
                    {
                      headerName: `${t("totalMovements")}`,
                      field: "total_movements",
                      description: `${t("totalMovements")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.total_movements}</>
                      ),
                    },
                    {
                      headerName: `${t("assignedResources")}`,
                      field: "assignedResources",
                      description: `${t("assignedResources")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.resources}</>
                      ),
                    },
                    {
                      headerName: `${t("created")}`,
                      field: "created_at",
                      description: `${t("created")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>
                          {params.row.created_at.split("T")[0]}{" "}
                          {new Date(params.row.created_at).toLocaleTimeString('it-IT')}
                        </>
                      ),
                    },
                    {
                      headerName: "",
                      field: "",
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>
                          <CustomIconButton
                            type="outlined"
                            icon={
                              <Tooltip title={t("edit")}>
                                <ModeEdit />
                              </Tooltip>
                            }
                            onClick={() =>
                              navigate(updateOrderUrl(params.row.id))
                            }
                          />
                          {/* <CustomIconButton
                            type="outlined"
                            confirm
                            icon={
                              <Tooltip title={t("delete")}>
                                <DeleteForeverOutlined />
                              </Tooltip>
                            }
                            onClick={() =>
                              deleteOrder(params.row.id, {
                                id: params.row.id.toString(),
                              } as DeleteOrderRequest).then((res: any) => {
                                if (res && !res.error) {
                                  enqueueSnackbar(t("orderDeletedSuccess"), {
                                    variant: "success",
                                  });
                                  setReload(!reload);
                                } else {
                                  enqueueSnackbar(
                                    `${t("orderDeletedError")} ${
                                      res?.err?.message
                                    }`,
                                    {
                                      variant: "error",
                                    }
                                  );
                                }
                              })
                            }
                          /> */}
                        </>
                      ),
                    },
                  ]}
                  rows={newOrders.filter(
                    (order: any) => order.status !== "Closed"
                  )}
                  stickyHeader
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div role="tabpanel" hidden={tabValue !== 1}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={4}>
                <CustomInput
                  label={t("orderId")}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setInputValueAll(e.target.value);
                    localStorage.setItem("SCTOrderIdAll", e.target.value);
                    getNewOrdersAll(e.target.value);
                  }}
                  value={inputValueAll}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTable
                  columns={[
                    {
                      headerName: `${t("orderId")}`,
                      field: "orderId",
                      description: `${t("orderId")}`,
                      filterable: false,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <Link
                          style={{ color: "black" }}
                          to={
                            typeof link === "string"
                              ? link
                              : link(params.row.order_id)
                          }
                        >
                          {params.row.order_id}
                        </Link>
                      ),
                    },
                    {
                      headerName: `${t("description")}`,
                      field: "description",
                      description: `${t("description")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.description}</>
                      ),
                    },
                    {
                      headerName: `${t("numDocks")}`,
                      field: "dock_num",
                      description: `${t("numDocks")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.dock_number}</>
                      ),
                    },
                    {
                      headerName: `${t("dock")}`,
                      field: "dock",
                      description: `${t("dock")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.dock}</>
                      ),
                    },
                    {
                      headerName: `${t("status")}`,
                      field: "status",
                      description: `${t("status")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.status}</>
                      ),
                    },
                    {
                      headerName: `${t("totalMovements")}`,
                      field: "total_movements",
                      description: `${t("totalMovements")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.total_movements}</>
                      ),
                    },
                    {
                      headerName: `${t("assignedResources")}`,
                      field: "assignedResources",
                      description: `${t("assignedResources")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>{params.row.resources}</>
                      ),
                    },
                    {
                      headerName: `${t("created")}`,
                      field: "created_at",
                      description: `${t("created")}`,
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>
                          {params.row.created_at.split("T")[0]}{" "}
                          {params.row.created_at.split("T")[1].split(".")[0]}
                        </>
                      ),
                    },
                    {
                      headerName: "",
                      field: "",
                      flex: 1,
                      renderCell: (params: GridRenderCellParams) => (
                        <>
                          <CustomIconButton
                            type="outlined"
                            icon={
                              <Tooltip title={t("edit")}>
                                <ModeEdit />
                              </Tooltip>
                            }
                            onClick={() =>
                              navigate(updateOrderUrl(params.row.id))
                            }
                          />
                          {/* <CustomIconButton
                            type="outlined"
                            confirm
                            icon={
                              <Tooltip title={t("delete")}>
                                <DeleteForeverOutlined />
                              </Tooltip>
                            }
                            onClick={() =>
                              deleteOrder(params.row.id, {
                                id: params.row.id.toString(),
                              } as DeleteOrderRequest).then((res: any) => {
                                if (res && !res.error) {
                                  enqueueSnackbar(t("orderDeletedSuccess"), {
                                    variant: "success",
                                  });
                                  setReload(!reload);
                                } else {
                                  enqueueSnackbar(
                                    `${t("orderDeletedError")} ${
                                      res?.err?.message
                                    }`,
                                    {
                                      variant: "error",
                                    }
                                  );
                                }
                              })
                            }
                          /> */}
                        </>
                      ),
                    },
                  ]}
                  rows={newOrdersAll}
                  stickyHeader
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrdersList;
