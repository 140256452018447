import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { cranesListUrl, mainUrl } from "../costants";
import { getCrane, updateCrane } from "../../../api/services/craneService";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";

type UpdateCraneProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string;
};

const UpdateCrane: React.FC<UpdateCraneProps> = ({
  setBreadcrumb,
  title,
  link,
}) => {
  const { id } = useParams(); //user uid
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    code: "",
    description: "",
    notes: "",
  });

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Link style={{ color: "#ffffff" }} key="2" to={link}>
        {t(title)}
      </Link>,
      <Typography color="#ffffff" key="3">
        {t("crane") + values.code}
      </Typography>,
    ]);
  }, [values]);

  useEffect(() => {
    getCrane(id!).then((res: any) => {
      console.log("RES", res);
      if (res && res.crane) {
        setValues({
          code: res.crane?.code,
          description: res.crane?.description,
          notes: res.crane?.notes,
        });
      }
      setLoading(false);
    });
  }, []);

  const handleSubmit = () => {
    updateCrane(id!, {
      code: values.code.toString(),
      description: values.description,
      notes: values.notes,
    }).then((res) => {
      if (res && res.result) {
        enqueueSnackbar(t("updateCraneSuccess"), { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(t("updateCraneError"), { variant: "error" });
      }
    });
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("crane") + " " + values.code}
          goBack={() => navigate(cranesListUrl)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("description")}
                  value={values.description}
                  handleChange={(e) =>
                    setValues({ ...values, description: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("notes")}
                  value={values.notes}
                  handleChange={(e) =>
                    setValues({ ...values, notes: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  code: "",
                  description: "",
                  notes: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateCrane;
