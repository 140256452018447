import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CustomTag from "./CustomTag";

type CustomSelectProps = {
  label: string;
  options: { label: string; value: string }[];
  value: string | string[];
  handleChange: (event: any) => void;
  validator?: {
    error?: boolean;
    helperText?: string;
  };
  multiple?: boolean;
  disabled?: boolean;
  search?: boolean;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  options,
  value,
  handleChange,
  validator,
  multiple,
  disabled,
  search,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredOptions = options.every((opt) => typeof opt.label === "string")
    ? options.filter((opt) =>
        opt.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : options;

  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        disabled={disabled}
        variant="outlined"
        labelId={label}
        multiple={multiple}
        value={value}
        label={label}
        onChange={handleChange}
        error={validator && validator.error ? validator.error : undefined}
        renderValue={
          multiple
            ? (selected: any) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {(selected as string[]).map((value: string) => (
                    <CustomTag
                      type="filled"
                      label={
                        options.find((opt) => opt.value === value)?.label ||
                        value
                      }
                    />
                  ))}
                </Box>
              )
            : undefined
        }
      >
        {search && (
          <MenuItem>
            <TextField
              autoFocus
              placeholder="Search..."
              size="small"
              fullWidth
              value={searchTerm}
              onChange={handleSearchChange}
              onClick={(event) => event.stopPropagation()}
              onMouseDown={(event) => event.stopPropagation()}
            />
          </MenuItem>
        )}
        {filteredOptions.map((opt: { label: string; value: string }) => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
      {validator && validator.error && (
        <FormHelperText error>{validator?.helperText || ""}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomSelect;

/*
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import CustomTag from "./CustomTag";

type CustomSelectProps = {
  label: string;
  options: { label: string; value: string }[];
  value: string | string[];
  handleChange: (event: any) => void;
  validator?: {
    error?: boolean;
    helperText?: string;
  };
  multiple?: boolean;
  disabled?: boolean;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  options,
  value,
  handleChange,
  validator,
  multiple,
  disabled,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        disabled={disabled}
        variant="outlined"
        labelId={label}
        multiple={multiple}
        value={value}
        label={label}
        onChange={handleChange}
        error={validator && validator.error ? validator.error : undefined}
        renderValue={
          multiple
            ? (selected: any) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {(selected as string[]).map((value: string) => (
                    <CustomTag
                      type="filled"
                      label={
                        options.find((opt) => opt.value === value)?.label ||
                        value
                      }
                    />
                  ))}
                </Box>
              )
            : undefined
        }
      >
        {options.map((opt: { label: string; value: string }) => (
          <MenuItem value={opt.value}>{opt.label}</MenuItem>
        ))}
      </Select>
      {validator && validator.error && (
        <FormHelperText error>{validator?.helperText || ""}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomSelect;
*/
