import {
  AddUserRequest,
  UpdateUserRequest,
  DeleteUserRequest,
  ResetPasswordRequest,
} from "../requests/userService";

import {
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";

import {
  createUserUrl,
  getCompanyByAdminUrl,
  getProfileUrl,
  getUserUrl,
  getUsersUrl,
  updateUserUrl,
  deleteuserUrl,
  getAllUsersUrl,
  resetPasswordUrl,
  checkUserUrl
} from "./url";

export const getUsers = (type: "controller" | "manager" | "admin") => {
  return verticalFetch(methodGet, getUsersUrl(type));
};

export const createUser = (body: AddUserRequest) => {
  return verticalFetch(methodPost, createUserUrl, JSON.stringify(body));
};

export const getProfile = () => {
  return verticalFetch(methodGet, getProfileUrl);
};

export const getUser = (uid: string) => {
  return verticalFetch(methodGet, getUserUrl(uid));
};

export const updateUser = (uid: string, body: UpdateUserRequest) => {
  return verticalFetch(methodPut, updateUserUrl(uid), JSON.stringify(body));
};

export const deleteUser = (uid: string, body: DeleteUserRequest) => {
  return verticalFetch(methodPut, deleteuserUrl(uid), JSON.stringify(body));
};

export const resetPassword = (uid: string, body: ResetPasswordRequest) => {
  return verticalFetch(methodPut, resetPasswordUrl(uid), JSON.stringify(body));
};

export const getCompanyByAdmin = (uid: string) => {
  return verticalFetch(methodGet, getCompanyByAdminUrl(uid));
};

export const getAllUsers = () => {
  return verticalFetch(methodGet, getAllUsersUrl);
};

export const checkUser = (email: string) => {
  return verticalFetch(methodGet, checkUserUrl(email));
}
