import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { infoManagementUrl, updateCraneUrl, routes } from "../costants";
import { getCranes, deleteCrane } from "../../../api/services/craneService";
import CustomLoading from "../../custom/CustomLoading";
import CustomIconButton from "../../custom/CustomIconButton";
import { DeleteForeverOutlined, ModeEdit } from "@mui/icons-material";
import { DeleteCraneRequest } from "../../../api/requests/craneService";
import { useSnackbar } from "notistack";
import CustomText from "../../custom/CustomText";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import Tooltip from "@mui/material/Tooltip";

type CranesListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const CranesList: React.FC<CranesListProps> = ({ setBreadcrumb, title }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={infoManagementUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  const [cranes, setCranes] = useState<any[]>([]);

  useEffect(() => {
    getCranes().then((res: any) => {
      if (res && res.cranes) {
        setCranes([...res.cranes]);
      } else {
        setCranes([]);
      }
      setLoading(false);
    });
  }, [reload]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t(title)}
          goBack={() => navigate(infoManagementUrl)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("code")}`,
              field: "code",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>{params.row.code}</>
              ),
            },
            {
              headerName: `${t("description")}`,
              field: "description",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>{params.row.description}</>
              ),
            },
            {
              headerName: `${t("notes")}`,
              field: "notes",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>{params.row.notes}</>
              ),
            },
            {
              headerName: "",
              field: "",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  <CustomIconButton
                    type="outlined"
                    icon={
                      <Tooltip title={t("edit")}>
                        <ModeEdit />
                      </Tooltip>
                    }
                    onClick={() => navigate(updateCraneUrl(params.row.code))}
                  />
                  <CustomIconButton
                    type="outlined"
                    confirm
                    icon={
                      <Tooltip title={t("delete")}>
                        <DeleteForeverOutlined />
                      </Tooltip>
                    }
                    onClick={() =>
                      deleteCrane(params.row.code, {
                        code: params.row.code.toString(),
                      } as DeleteCraneRequest).then((res: any) => {
                        if (res && !res.error) {
                          enqueueSnackbar(t("craneDeletedSuccess"), {
                            variant: "success",
                          });
                          setReload(!reload);
                        } else {
                          enqueueSnackbar(
                            `${t("craneDeletedError")} ${res?.err?.message}`,
                            {
                              variant: "error",
                            }
                          );
                        }
                      })
                    }
                  />
                </>
              ),
            },
          ]}
          rows={cranes}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <CustomIconButton
            type="outlined"
            icon={<AddCircleOutlinedIcon fontSize="large" />}
            onClick={() => navigate(routes.createCrane)}
          />
          <CustomText type="h6" label={t("createCrane")} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CranesList;
