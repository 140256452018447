import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { infoManagementUrl, mainUrl, signalersListUrl } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { createSignaler } from "../../../api/services/signalerService";
import { useSnackbar } from "notistack";
import { AddSignalerRequest } from "../../../api/requests/signalerService";

type CreateSignalerProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const CreateSignaler: React.FC<CreateSignalerProps> = ({
  setBreadcrumb,
  title,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [values, setValues] = useState({
    code: "",
    description: "",
    notes: "",
  });

  const handleSubmit = () => {
    createSignaler({
      code: values.code,
      description: values.description,
      notes: values.notes,
    } as AddSignalerRequest).then((res) => {
      if (res && res.signaler) {
        setValues({
          code: "",
          description: "",
          notes: "",
        });
        enqueueSnackbar(t("signalerCreatedSuccess"), { variant: "success" });
        navigate(signalersListUrl);
      } else {
        enqueueSnackbar(`${t("signalerCreatedError")} ${res?.err?.message}`, {
          variant: "error",
        });
      }
    });
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t(title)}
          goBack={() => navigate(infoManagementUrl)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomInput
                  label={t("description")}
                  value={values.description}
                  handleChange={(e) =>
                    setValues({ ...values, description: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInput
                  label={t("notes")}
                  value={values.notes}
                  handleChange={(e) =>
                    setValues({ ...values, notes: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  code: "",
                  description: "",
                  notes: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};
export default CreateSignaler;
