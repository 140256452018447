import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomCard from "../../custom/CustomCard";
import {
  People,
  BarChart,
  AssignmentOutlined,
  ListAlt,
  SpaceDashboardOutlined,
} from "@mui/icons-material";
import { roles, routes } from "../costants";
import CustomButton from "../../custom/CustomButton";
import { lightColor } from "../../custom/utils";

type HomePageProps = {
  role: string;
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const HomePage: React.FC<HomePageProps> = ({ role, setBreadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  document.body.style.backgroundColor = "#ffffff";

  useEffect(() => {
    setBreadcrumb([
      <Typography color="#ffffff" key="1">
        {t("home")}
      </Typography>,
    ]);
  }, []);

  const handleRoleIconCards = () => {
    switch (role) {
      case roles.admin:
        return [
          {
            img: (
              <People
                style={{
                  color: lightColor,
                  fontSize: "159px",
                }}
              />
            ),
            text: [t("createUser"), t("updateUser")],
            link: [routes.createUsers, routes.usersList],
          },
          {
            img: (
              <BarChart
                style={{
                  color: lightColor,
                  fontSize: "159px",
                }}
              />
            ),
            text: [t("infoManagement")],
            link: [routes.infoManagement],
          },
        ];
      case roles.controller:
        return [
          {
            img: (
              <ListAlt
                style={{
                  color: lightColor,
                  fontSize: "159px",
                }}
              />
            ),
            text: [t("ordersList")],
            link: [routes.ordersList],
          },
          {
            img: (
              <AssignmentOutlined
                style={{
                  color: lightColor,
                  fontSize: "159px",
                }}
              />
            ),
            text: [t("assignResources")],
            link: [routes.assignResources],
          },
          {
            img: (
              <SpaceDashboardOutlined
                style={{
                  color: lightColor,
                  fontSize: "159px",
                }}
              />
            ),
            text: [t("dashboard")],
            link: [routes.dashboardController],
          },
        ];
      case roles.manager:
        return [
          {
            img: (
              <BarChart
                style={{
                  color: lightColor,
                  fontSize: "159px",
                }}
              />
            ),
            text: [t("dashboard")],
            link: [routes.dashboardManager],
          },
        ];
      default:
        return [];
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      {handleRoleIconCards().map((iconItem) => (
        <Grid item xs={12} md={6}>
          <CustomCard
            content={
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>{iconItem.img}</Grid>
              </Grid>
            }
            actions={iconItem.text.map(
              (buttonText: string, buttonIndex: number) => (
                <CustomButton
                  fullWidth
                  type="contained"
                  label={buttonText}
                  onClick={() => navigate(iconItem.link[buttonIndex])}
                />
              )
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default HomePage;
