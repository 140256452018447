import React, { useEffect, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getDashboardManagerUrl, mainUrl } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomSelect from "../../custom/CustomSelect";
import { useSnackbar } from "notistack";
import { getCranes } from "../../../api/services/craneService";
import CustomLoading from "../../custom/CustomLoading";
import { getOrders } from "../../../api/services/orderService";
import Autocomplete from "@mui/material/Autocomplete";
import { getDashboardManager } from "../../../api/services/dashboardService";

type DashboardManagerProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const DashboardManagerOverview: React.FC<DashboardManagerProps> = ({
  setBreadcrumb,
  title,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();

  const batch_id = searchParams.get("batch_id");
  const batch_status = searchParams.get("batch_status");
  const shift_id = searchParams.get("shift_id");
  const crane_id = searchParams.get("crane_id");
  const start = searchParams.get("start");
  const end = searchParams.get("end");

  document.body.style.backgroundColor = "#ffffff";

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [values, setValues] = useState({
    batch_id: batch_id?.replaceAll("%20", " ") || "",
    batch_status: batch_status || "true",
    shift_id: shift_id || "",
    crane_id: crane_id || "",
    start: start || "",
    end: end || "",
  });
  const [cranes, setCranes] = useState<any[]>([]);
  const [order, setOrder] = useState(true);
  const [orders, setOrders] = useState<any[]>([]);

  useEffect(() => {
    if (batch_status && batch_status === "true") {
      setOrder(true);
    }
    if (batch_status && batch_status === "false") {
      setOrder(false);
    }
  }, []);

  useEffect(() => {
    getCranes().then((res: any) => {
      if (res && res.cranes) {
        setCranes([...res.cranes]);
      } else {
        setCranes([]);
      }
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    getOrders().then((res: any) => {
      if (res && res.orders) {
        setOrders([
          ...res.orders.filter((order: any) => order.status === "Open"),
        ]);
      } else {
        setOrders([]);
      }
      setLoading(false);
    });
  }, []);

  const handleSubmit = () => {
    let tmpCrane = cranes?.filter(
      (crane: any) => crane.description === values.crane_id
    )[0];
    let code = "";
    let description = "";
    if (tmpCrane) {
      code = tmpCrane.code;
      description = tmpCrane.description;
    }
    if (!values.batch_status) {
      enqueueSnackbar(t("enterBatchStatus"), { variant: "error" });
      return;
    } else if (!order) {
      if (values.batch_id || (values.start && values.end)) {
        getDashboardManager(
          values.batch_id!.split(" - ")[0],
          values.batch_status!,
          values.shift_id || "",
          values.crane_id || "",
          values.start || "",
          values.end || ""
        ).then((res: any) => {
          if (res && res.dashboard) {
            navigate(
              getDashboardManagerUrl(
                values.batch_id,
                values.batch_status,
                values.shift_id,
                code,
                description,
                values.start,
                values.end
              )
            );
          } else {
            enqueueSnackbar(t("noVesselsForFilters"), { variant: "error" });
          }
        });
      } else {
        enqueueSnackbar(t("enterStartEndDate"), { variant: "error" });
        return;
      }
    } else {
      getDashboardManager(
        values.batch_id!.split(" - ")[0],
        values.batch_status!,
        values.shift_id || "",
        values.crane_id || "",
        values.start || "",
        values.end || ""
      ).then((res: any) => {
        if (res && res.dashboard) {
          navigate(
            getDashboardManagerUrl(
              values.batch_id,
              values.batch_status,
              values.shift_id,
              code,
              description,
              values.start,
              values.end
            )
          );
        } else {
          enqueueSnackbar(t("noVesselsForFilters"), { variant: "error" });
        }
      });
    }
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("batchStatus")}`}
                  options={[
                    { label: t("openBatches"), value: "true" },
                    { label: t("closedBatches"), value: "false" },
                  ]}
                  value={values.batch_status}
                  handleChange={(e) => {
                    setValues({  
                      batch_id: "",
                      shift_id: "",
                      crane_id: "",
                      start: "",
                      end: "", 
                      batch_status: e.target.value 
                    });
                    if (e.target.value === "true") {
                      setOrder(true);
                    } else {
                      setOrder(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {order ? (
                  <Autocomplete
                    disablePortal
                    value={values.batch_id}
                    options={orders?.map(
                      (order: any) => order.order_id + " - " + order.description
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setValues({ ...values, batch_id: value });
                      } else {
                        setValues({ ...values, batch_id: "" });
                      }
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label={`${t("batchId")}`} />
                    )}
                  />
                ) : (
                  <CustomInput
                    label={t("batchId")}
                    value={values.batch_id}
                    handleChange={(e) =>
                      setValues({ ...values, batch_id: e.target.value })
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("shiftId")}`}
                  options={[
                    { label: t("shift1"), value: "1" },
                    { label: t("shift2"), value: "2" },
                    { label: t("shift3"), value: "3" },
                    { label: t("shift4"), value: "4" },
                  ]}
                  disabled={values.batch_status === "true"}
                  value={values.shift_id}
                  handleChange={(e) =>
                    setValues({ ...values, shift_id: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={
                    values.crane_id
                      ? cranes
                          ?.map((crane: any) => crane.description)
                          .filter((c) => c.includes(values.crane_id))[0]
                      : ""
                  }
                  disabled={values.batch_status === "true"}
                  options={cranes?.map((crane: any) => crane.description)}
                  onChange={(e, value) => {
                    if (value) {
                      setValues({ ...values, crane_id: value });
                    } else {
                      setValues({ ...values, crane_id: "" });
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label={`${t("craneId")}`} />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("start")}
                  type="date"
                  value={values.start}
                  handleChange={(e) =>
                    setValues({ ...values, start: e.target.value })
                  }
                  disabled={values.batch_status === "true"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("end")}
                  type="date"
                  value={values.end}
                  handleChange={(e) =>
                    setValues({ ...values, end: e.target.value })
                  }
                  disabled={values.batch_status === "true"}
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  batch_id: "",
                  batch_status: "",
                  shift_id: "",
                  crane_id: "",
                  start: "",
                  end: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardManagerOverview;
