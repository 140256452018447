import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { mainUrl, ordersListUrl } from "../costants";
import { getOrder, updateOrder } from "../../../api/services/orderService";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";
import CustomSelect from "../../custom/CustomSelect";
import { getDockInfo } from "../../../api/services/dockService";
import { getProfile } from "../../../api/services/userService";

type UpdateOrderProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string;
};

const UpdateOrder: React.FC<UpdateOrderProps> = ({
  setBreadcrumb,
  title,
  link,
}) => {
  const { id } = useParams(); //order id
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [dockNums, setDockNums] = useState<any[]>([]);
  const [dockModes, setDockModes] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    id: "",
    order_id: "",
    description: "",
    dock_num: 0,
    dock: 0,
    status: "",
    total_mov: "",
    assigned_date: "",
  });

  const [user, setUser] = useState<any>({
    id: "",
    name: "",
    email: "",
    role: "",
  });

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Link style={{ color: "#ffffff" }} key="2" to={link}>
        {t(title)}
      </Link>,
      <Typography color="#ffffff" key="3">
        {t("order") + values.order_id}
      </Typography>,
    ]);
  }, [values]);

  useEffect(() => {
    getProfile().then((res: any) => {
      if (res && res.user) {
        setUser({
          id: res.user?.uid,
          name: res.user?.name,
          email: res.user?.email,
          role: res.user?.roles[0],
        });
      }
    });
  }, []);

  useEffect(() => {
    getOrder(id!).then((res: any) => {
      if (res && res.order) {
        setValues({
          id: res.order?.id,
          order_id: res.order?.order_id,
          description: res.order?.description,
          dock_num: Number(res.order?.dock_number),
          dock: Number(res.order?.dock),
          status: res.order?.status,
          total_mov: res.order?.total_movements,
          assigned_date: (res.order?.assigned_date).split("T")[0],
        });
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getDockInfo().then((res: any) => {
      if (res && res.dock_info) {
        setDockNums([...res.dock_info.dock_nums]);
        setDockModes([...res.dock_info.dock_mode]);
      } else {
        setDockNums([]);
        setDockModes([]);
      }
      setLoading(false);
    });
  }, []);

  const handleSubmit = () => {
    if (!values.total_mov || parseInt(values.total_mov, 10) <= 0) {
      enqueueSnackbar(t("totalMovRequired"), { variant: "error" });
      return;
    }
    console.log("VALUES", user);
    updateOrder(id!, {
      id: values.id,
      order_id: values.order_id,
      description: values.description,
      dock_number: Number(values.dock_num),
      dock: Number(values.dock),
      status: values.status,
      total_mov: values.total_mov,
      assigned_date: values.assigned_date,
      user_id: user.id,
      user_name: user.name,
      user_email: user.email,
      user_role: user.role,

    }).then((res) => {
      if (res && res.order) {
        enqueueSnackbar(t("updateOrderSuccess"), { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(t("updateOrderError"), { variant: "error" });
      }
      console.log("RES", res);
    });
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("order") + " " + values.order_id}
          goBack={() => navigate(ordersListUrl)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("orderId")}
                  value={values.order_id}
                  handleChange={(e) =>
                    setValues({ ...values, order_id: e.target.value })
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("description")}
                  value={values.description}
                  handleChange={(e) =>
                    setValues({ ...values, description: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("numDocks")}`}
                  value={Number(values.dock_num) as any}
                  options={dockNums.map((dockNum) => ({
                    value: dockNum.code,
                    label: dockNum.description,
                  }))}
                  handleChange={(e) =>
                    setValues({ ...values, dock_num: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("dockMode")}`}
                  value={Number(values.dock) as any}
                  options={dockModes.map((dockMode) => ({
                    value: dockMode.code,
                    label: dockMode.description,
                  }))}
                  handleChange={(e) =>
                    setValues({ ...values, dock: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("status")}`}
                  value={values.status}
                  options={[
                    { label: t("created"), value: "Created" },
                    { label: t("open"), value: "Open" },
                    { label: t("close"), value: "Closed" },
                  ]}
                  handleChange={(e) =>
                    setValues({ ...values, status: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("totalMovements")}
                  value={values.total_mov}
                  handleChange={(e) =>
                    setValues({ ...values, total_mov: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("assignedDate")}
                  type="date"
                  value={values.assigned_date}
                  handleChange={(e) =>
                    setValues({ ...values, assigned_date: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateOrder;
